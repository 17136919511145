<template>
    <div>
        <div class="fullwidth vh-100">

            <div class="transferflow-main">

                <div class="row transferflow-main-nav bg-info p-20 flex-left-right">
                    <div class="col-lg-2 col-md-6 col-sm-6 col-xs-6 justify-lg-end justify-start pl-5">
                        <router-link to="/home">
                            <img style="max-width:140px" src="/assets/remittance-assets/img/transcrypt-logo-colour.png"/>
                        </router-link>

                    </div>

                    <div class="col-lg-2 col-md-6 col-sm-6 col-xs-6 col-lg-push-8 text-grey justify-lg-start justify-end pr-5">
                        <span class="pt-1 user-menu-container">
                            <user-icon size="2x" class="custom-class pointer" @click="showUserMenu()"></user-icon>
                             <div class="user-menu" id="user-menu">
                                 <small><strong>{{userEmail || "N/A"}}</strong></small>
                                <div style="width:100%;height:1px;border:1px solid #EEE5E9" class="mt-1 mb-1">
                                </div>
                                 <small class="pointer pt-1 pb-1" @click="goToDashboard()">Go to dashboard</small>
                                 <small class="pointer" @click="logout()">Logout</small>
                             </div>
<!--                            <x-icon size="1.5x" class="pointer" style="text-align: right" @click="goToDashboard()"></x-icon>-->
                        </span>


                    </div>

                    <div class="col-lg-6 col-lg-pull-2 col-lg-offset-1 col-md-12 col-sm-12 col-xs-12 px-0 middle-part text-center">
                        <div class="horizontal-steps mt-1 mb-3 w-75 mx-auto">
                            <div class="step step1 step-name pointer" id="remittance-info" @click="goToRemittanceForm($event)" :class="[remittanceInfoStepperStyle]">
                                <div class="step-border"></div>
                                <div class="step-circle"></div>
                                <div class="step-text hidden-xs pointer"
                                >
                                    <small>
                                        Amount
                                    </small>
                                </div>
                            </div>
                            <div class="step step2 step-name pointer"  id="sender-info" @click="goToSenderDetails($event)" :class="[senderInfoStepperStyle]">
                                <div class="step-border"></div>
                                <div class="step-circle"></div>
                                <div class="step-text hidden-xs pointer"
                                    >
                                    <small>
                                        My profile
                                    </small>
                                </div>
                            </div>
                            <div class="step step3 step-name pointer"  id="recipient-info" @click="goToRecipientDetails($event)" :class="[recipientInfoStepperStyle]">
                                <div class="step-border"></div>
                                <div class="step-circle"></div>
                                <div class="step-text hidden-xs pointer"
                                    >
                                    <small>
                                        My recipient
                                    </small>
                                </div>
                            </div>
                            <div class="step step4 step-name pointer" id="review-info" @click="goToReviewDetails($event)" :class="[reviewInfoStepperStyle]">
                                <div class="step-border"></div>
                                <div class="step-circle"></div>
                                <div class="step-text hidden-xs pointer"
                                     >
                                    <small>
                                        Review
                                    </small>
                                </div>
                            </div>
                            <div class="step step5 step-last step-name pointer" id="payment-info" @click="goToMakePayment($event)" :class="[paymentInfoStepperStyle]">
                                <div class="step-border"></div>
                                <div class="step-circle"></div>
                                <div class="step-text hidden-xs pointer"
                                     >
                                    <small>
                                        Payment
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="transferflow-main-content p-20">
                    <main class="p-10 w-100">
                        <div class="row p-20" style="background:white;">


                            <router-view></router-view>


                        </div>
                    </main>

                    <!-- Start Footer Area -->
                    <!--TODO hide on mobile-->
                    <footer class="dashboard-main-footer">
                        <div class="footer-area-bottom">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <div class="copyright">
                                            <p>
                                                ©2021
                                                <span class="text-success"><strong>TransCrypt</strong></span> All Rights Reserved.
                                                Contact us at <a class="text-success" href="mailto:support@transcryptglobal.com?subject=Crypto%20Transfer%20Enquiry">support@transcryptglobal.com</a> if you have questions.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                    <!-- End Footer Area -->
                </div>

            </div>

        </div>
    </div>
</template>
<script>
    import {mapGetters} from "vuex";
    import {UserIcon,XIcon } from 'vue-feather-icons'

    export default {
        components: {
            UserIcon,
            // XIcon
        },
        data() {
            return {
                toggleUserMenu: false,

                debugConsole : false,
            }
        },
        computed: {
            ...mapGetters([
                "stepperStateGetters",
                "currentActiveStepperGetters",
                "userDetailsGetter",
            ]),

            userEmail(){
                return this.userDetailsGetter && this.userDetailsGetter.email;
            },

            remittanceInfoStepperStyle() {
                return {
                    'active': this.stepperStateGetters.length > 0 && this.stepperStateGetters.find(el => el.stepName === 'remittance-info').active,
                    'done': this.stepperStateGetters.length > 0 && this.stepperStateGetters.find(el => el.stepName === 'remittance-info').done,
                }
            },
            senderInfoStepperStyle() {
                return {
                    'active': this.stepperStateGetters.length > 0 && this.stepperStateGetters.find(el => el.stepName === 'sender-info').active,
                    'done': this.stepperStateGetters.length > 0 && this.stepperStateGetters.find(el => el.stepName === 'sender-info').done,
                }
            },

            recipientInfoStepperStyle() {
                return {
                    'active': this.stepperStateGetters.length > 0 && this.stepperStateGetters.find(el => el.stepName === 'recipient-info').active,
                    'done': this.stepperStateGetters.length > 0 && this.stepperStateGetters.find(el => el.stepName === 'recipient-info').done,
                }
            },

            reviewInfoStepperStyle() {
                return {
                    'active': this.stepperStateGetters.length > 0 && this.stepperStateGetters.find(el => el.stepName === 'review-info').active,
                    'done': this.stepperStateGetters.length > 0 && this.stepperStateGetters.find(el => el.stepName === 'review-info').done,
                }
            },

            paymentInfoStepperStyle() {
                return {
                    'active': this.stepperStateGetters.length > 0 && this.stepperStateGetters.find(el => el.stepName === 'payment-info').active,
                    'done': this.stepperStateGetters.length > 0 && this.stepperStateGetters.find(el => el.stepName === 'payment-info').done,
                }
            },

        },
        watch: {
            $route(to, from) {
                if(this.debugConsole)    console.log("Route is being watched");
                if (to.path === '/transfer/remittance-form') {
                    let target = "remittance-info";
                    this.$store.commit('UPDATE_STEPPER', target);
                } else if (to.path === '/transfer/sender-details') {
                    let target = "sender-info";
                    this.$store.commit('UPDATE_STEPPER', target);
                } else if (to.path === '/transfer/recipient-details') {
                    let target = "recipient-info";
                    this.$store.commit('UPDATE_STEPPER', target);
                } else if (to.path === '/transfer/review-details') {
                    let target = "review-info";
                    this.$store.commit('UPDATE_STEPPER', target);
                } else if (to.path === '/transfer/kyc-details' || to.path === '/transfer/make-payment') {
                    let target = "payment-info";
                    this.$store.commit('UPDATE_STEPPER', target);
                }

            },

        },
        beforeMount() {
            // this.initiateStepperState();
        },
        mounted() {
            //if the user first comes to the account layout page, we need to show the first step by default,
            // this.initiateStepperState();

        },
        methods: {

            // getActiveStyle(target)
            // {
            //   console.log("Target ", target);
            //   let findStep = this.stepperStateGetters && this.stepperStateGetters.filter(el => el.stepName === target);
            //   console.log("FindStep", findStep);
            //   return findStep.active;
            // },
            goToRemittanceForm(event) {
              if(this.debugConsole)  console.log("click on remittance stepper");

                let target = event.currentTarget.id;
                if(this.debugConsole)   console.log("TargetID", target);

                let stepper = this.stepperStateGetters && this.stepperStateGetters.find(el => el.stepName === target);
                if (stepper && !stepper.done) {
                    if(this.debugConsole)    console.log("Not clickable");
                    return;
                } else {
                    if(this.debugConsole)    console.log("Target on the stepper", target);
                    if (target) {
                        this.$store.commit('UPDATE_STEPPER', target);
                    }
                    this.$router.push('/transfer/remittance-form').catch(() => {
                    });
                }

            },
            goToSenderDetails(event) {
                if(this.debugConsole)   console.log("click on sender stepper");

                let target = event.currentTarget.id;
                if(this.debugConsole)   console.log("TargetID", target);

                let stepper = this.stepperStateGetters && this.stepperStateGetters.find(el => el.stepName === target);
                if (stepper && !stepper.done) {
                    if(this.debugConsole)   console.log("Not clickable");
                    return;

                } else {
                    if(this.debugConsole)   console.log("Target on the stepper", target);
                    if (target) {
                        this.$store.commit('UPDATE_STEPPER', target);
                    }
                    this.$router.push('/transfer/sender-details').catch(() => {
                    });
                }

            },
            goToRecipientDetails(event) {
                if(this.debugConsole)    console.log("click on recipient stepper");

                let target = event.currentTarget.id;
                if(this.debugConsole)   console.log("TargetID", target);

                let stepper = this.stepperStateGetters && this.stepperStateGetters.find(el => el.stepName === target);

                if (stepper && !stepper.done) {
                    if(this.debugConsole)     console.log("Not clickable");
                    return;
                } else {
                    if(this.debugConsole)    console.log("Target on the stepper", target);
                    if (target) {
                        this.$store.commit('UPDATE_STEPPER', target);
                    }

                    this.$router.push('/transfer/recipient-details').catch(() => {
                    });
                }

            },
            goToReviewDetails(event) {
                if(this.debugConsole)   console.log("click on review stepper");

                let target = event.currentTarget.id;
                if(this.debugConsole)  console.log("TargetID", target);

                let stepper = this.stepperStateGetters && this.stepperStateGetters.find(el => el.stepName === target);
                if (stepper && !stepper.done) {
                    if(this.debugConsole)     console.log("Not clickable");
                    return;
                } else {
                    if(this.debugConsole)   console.log("Target on the stepper", target);
                    if (target) {
                        this.$store.commit('UPDATE_STEPPER', target);
                    }
                    this.$router.push('/transfer/review-details').catch(() => {
                    });
                }

            },
            goToMakePayment(event) {
                if(this.debugConsole)    console.log("click on make payment stepper");

                let target = event.currentTarget.id;
                if(this.debugConsole)   console.log("TargetID", target);
                let stepper = this.stepperStateGetters && this.stepperStateGetters.find(el => el.stepName === target);
                if (stepper && !stepper.done) {
                    if(this.debugConsole)     console.log("Not clickable");
                    return;
                } else {
                    if(this.debugConsole)    console.log("Target on the stepper", target);
                    if (target) {
                        this.$store.commit('UPDATE_STEPPER', target);
                    }
                    this.$router.push('/transfer/make-payment').catch(() => {
                    });
                }

            },

            initiateStepperState() {
                let elements = document.getElementsByClassName('step-text');
                if(this.debugConsole)   console.log("Elements", elements);

                let stepNameElements = document.getElementsByClassName('step-name');
                if(this.debugConsole)   console.log("StepNameElements", stepNameElements);

                let arr = [];
                for (let i = 0; i < stepNameElements.length; i++) {
                    arr.push({stepName: stepNameElements[i].id, index: i, active: false, done: false})
                }

                //set arr[0].active to be true as default
                arr[0].active = true;
                arr[0].done = true;
                if(this.debugConsole)   console.log("Arr", arr);

                //commit to state
                this.$store.commit('INITIATE_STEPPER', arr);
            },

            showUserMenu() {
                this.toggleUserMenu = !this.toggleUserMenu;
                if(this.toggleUserMenu)
                {
                    document.querySelector('#user-menu').style.visibility = 'visible';
                }
                else
                {
                    document.querySelector('#user-menu').style.visibility = 'hidden';
                }
            },
            logout(){
                this.$router.push('/account/logout').catch(()=>{});
            },
            goToDashboard(){
                this.$router.push('/dashboard').catch(()=>{});
            }
        }
    }
</script>
<style>

</style>
